._gap-24{gap:24;}
._jc-center{justify-content:center;}
._fg-1{flex-grow:1;}
._fs-1{flex-shrink:1;}
._fb-auto{flex-basis:auto;}
._fd-column{flex-direction:column;}
._miw-0{min-width:0;}
._mih-0{min-height:0;}
._pos-relative{position:relative;}
._dsp-flex{display:flex;}
._ai-center{align-items:center;}
._fs-0{flex-shrink:0;}
._ai-stretch{align-items:stretch;}
._col-d0t1114586361{color:hsl(0, 0%, 9.0%);}
._col-d0t52942863{color:hsl(0, 0%, 42.5%);}