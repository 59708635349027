._mt-0{margin-top:0;}
._mr-0{margin-right:0;}
._mb-0{margin-bottom:0;}
._ml-0{margin-left:0;}
._col-d0t1114586361{color:hsl(0, 0%, 9.0%);}
._ff-Inter{font-family:Inter;}
._dsp-flex{display:flex;}
._tt-none{text-transform:none;}
._fow-700{font-weight:700;}
._ls--3{letter-spacing:-3;}
._fos-46{font-size:46;}
._lh-56{line-height:56;}