._w-550{width:550;}
._pr-7{padding-right:7;}
._pl-7{padding-left:7;}
._fd-column{flex-direction:column;}
._miw-0{min-width:0;}
._mih-0{min-height:0;}
._pos-relative{position:relative;}
._fb-auto{flex-basis:auto;}
._dsp-flex{display:flex;}
._fs-0{flex-shrink:0;}
._ai-stretch{align-items:stretch;}
@media screen and (min-width: 801px) { :root:root ._pr-_gtSm_32{padding-right:32;} }
@media screen and (min-width: 801px) { :root:root ._pl-_gtSm_32{padding-left:32;} }
._bg-f9f9f935{background-color:#f9f9f9;}
._col-d0t1114586361{color:hsl(0, 0%, 9.0%);}
._fos-22{font-size:22;}
._fos-16{font-size:16;}