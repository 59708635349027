._bg-f9f9f935{background-color:#f9f9f9;}
._dsp-flex{display:flex;}
._col-d0t1114586361{color:hsl(0, 0%, 9.0%);}
._pos-absolute{position:absolute;}
._t-0{top:0;}
._l-0{left:0;}
._r-0{right:0;}
._b-0{bottom:0;}
._fd-row{flex-direction:row;}
._miw-0{min-width:0;}
._mih-0{min-height:0;}
._fb-auto{flex-basis:auto;}
._fs-0{flex-shrink:0;}
._ai-stretch{align-items:stretch;}
._h-10037{height:100%;}
._fg-1{flex-grow:1;}
._fs-1{flex-shrink:1;}
._jc-center{justify-content:center;}
._fd-column{flex-direction:column;}
._pos-relative{position:relative;}
._ai-center{align-items:center;}
._h-auto{height:auto;}
._mah-auto{max-height:auto;}
._w-0{width:0;}
._maw-0{max-width:0;}
._bbw-0{border-bottom-width:0;}
._brw-1{border-right-width:1;}
._bs-solid{border-style:solid;}
._btw-0{border-top-width:0;}
._blw-0{border-left-width:0;}
._btc-d0t196083813{border-top-color:hsl(0, 0%, 92.0%);}
._brc-d0t196083813{border-right-color:hsl(0, 0%, 92.0%);}
._bbc-d0t196083813{border-bottom-color:hsl(0, 0%, 92.0%);}
._blc-d0t196083813{border-left-color:hsl(0, 0%, 92.0%);}
._transform-d0t607864201{transform:translateX(-0.5) translateY(0);}
._bg-d0t1821305091{background-color:rgba(0,0,0,0.00);}