._bg-f9f9f935{background-color:#f9f9f9;}
._w-100{width:100;}
._h-100{height:100;}
._miw-100{min-width:100;}
._maw-100{max-width:100;}
._mah-100{max-height:100;}
._mih-100{min-height:100;}
._jc-center{justify-content:center;}
._fd-column{flex-direction:column;}
._pos-relative{position:relative;}
._fb-auto{flex-basis:auto;}
._dsp-flex{display:flex;}
._fs-0{flex-shrink:0;}
._ai-center{align-items:center;}
._miw-0{min-width:0;}
._mih-0{min-height:0;}
._ai-stretch{align-items:stretch;}
._mt-0{margin-top:0;}
._mr-0{margin-right:0;}
._mb-0{margin-bottom:0;}
._ml-0{margin-left:0;}
._col-d0t1114586361{color:hsl(0, 0%, 9.0%);}
._ff-Inter{font-family:Inter;}
._tt-none{text-transform:none;}
._fow-700{font-weight:700;}
._ls--3{letter-spacing:-3;}
._fos-46{font-size:46;}
._lh-56{line-height:56;}